<template>
  <div>
    <FehlerMeldung
      v-if="error"
    />
    <div v-if="ready">
      <v-card
        v-if="data.length == 0"
        ref="message"
        class="mx-auto my-12"
        max-width="800"
      >
        <v-card-title>
          Sie haben leider noch keine Themen bewertet!
        </v-card-title>
      </v-card>
      <div v-else>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="search.searchzeitraum"
              :items="zeitraume"
              label="Zeitraum"
              clearable
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="search.searchthemen"
              :items="themen"
              label="Thema"
              clearable
              :item-text="item => item.name"
              item-value="id"
            >
              <template #selection="{ item }">
                <span>{{ item.name }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!--Die Tabelle-->
        <v-data-table
          ref="datatable"
          :headers="headers"
          :items="getdata"
          item-key="id"
          :expanded.sync="expanded"
          show-expand
          @click:row="clicked"
        >
          <!--Dropdown -->
          <template #expanded-item="{ item }">
            <td :colspan="2">
              <v-list>
                <!-- Thema Name-->
                <v-list-item>
                  <v-text-field
                    label="Thema"
                    :name="'Thema' + item.id"
                    :value="item.bewertung.thema.name"
                    readonly
                  />
                </v-list-item>
                <!-- Gewichtung -->
                <v-list-item>
                  <v-text-field
                    label="Gewichtung"
                    :value="item.bewertung.thema.gewichtung"
                    readonly
                  />
                </v-list-item>
                <!-- Fragen und Punkte -->
                <v-list-group
                  v-for="frage in item.bewertung.bewertung"
                  :key="frage.frage"
                  :value="false"
                  no-action
                  sub-group
                >
                  <template #activator>
                    <v-list-item-content>
                      <div>
                        {{ frage.frage }}
                      </div>
                    </v-list-item-content>
                  </template>

                  <v-list-item>
                    <v-text-field
                      :label="getLabel(frage.punkte[0].punkte)"
                      name="3Punkte"
                      :value="frage.punkte[0].name"
                      readonly
                    />
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      :label="getLabel(frage.punkte[1].punkte)"
                      name="2Punkte"
                      :value="frage.punkte[1].name"
                      readonly
                    />
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      :label="getLabel(frage.punkte[2].punkte)"
                      name="1Punkte"
                      :value="frage.punkte[2].name"
                      readonly
                    />
                  </v-list-item>
                </v-list-group>
              </v-list>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div
      v-else
      ref="loading"
      class="text-center progress-circular-container"
    >
      <v-progress-circular
        name="v-progress-circular"
        :size="50"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";

export default {
  components: {
    FehlerMeldung
  },
  props: {
    zeitraume: {
      type: Array,
      required: true
    },
    themen: {
      type: Array,
      required: true
    },
    ready: {
      type: Boolean,
      required: true,
      default: false
    },
    bewertungen: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    anzahlthemen: null,
    error: null,
    headers: [
      { text: "Zeitraum", value: "zeitraum" },
      { text: "Thema", value: "bewertung.thema.name" },
      { text: "", value: "data-table-expand" }
    ],
    headersall: [],
    expanded: [],
    search: {
      searchthemen: null,
      searchzeitraum: null
    }
  }),
  computed: {

    getdata(){
      let data = this.data
      if(this.search.searchzeitraum != null){
        data = data.filter(data=> data.zeitraum == this.search.searchzeitraum)
      }
      if(this.search.searchthemen!= null){
        data = data.filter(da=> da.bewertung.thema_id == this.search.searchthemen)

      }
      return data
    }
  },
  methods: {
    clicked(value) {
      if (this.expanded.includes(value)){
        this.expanded = this.expanded.filter(item  => item != value);
      }
      else {
        this.expanded.push(value)
      }
    },
    getLabel(punkte){
      return punkte + " Punkte"
    }
  }
};
</script>
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
<style scoped>
.progress-circular-container{
  padding-top: 50px;
  padding-bottom: 50px
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
