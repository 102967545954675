<template>
  <v-alert
    v-if="getErrorMessage"
    max-width="800"
    class="mx-auto"
    :value="getErrorMessage !== null"
    type="error"
    dismissible
  >
    {{ getErrorMessage }}
  </v-alert>
</template>
<script>
export default {
  props: {
    error: {
      type: Error,
      required: false,
      default: Error
    }
  },
  computed: {
    getErrorMessage() {
      if(this.error != null ){
        console.log(this.error)
      }
      let errorMessage = "";
      if (this.error && this.error.response) {
        if (this.error.response.status >= 500) {
          errorMessage = "Ein unkontrollierter Fehler ist aufgetreten!";
        } else if (this.error.response.status >= 400) {
          if (this.error.response.data.message)
            errorMessage =
              "Ein Fehler ist aufgetreten: " + this.error.response.data.message;
          else errorMessage = "Ein unkontrollierter Fehler ist aufgetreten!";
        }
      } else {
        if (this.error) {
          errorMessage = "Ein Fehler ist aufgetreten!";
        } else {
          errorMessage = "";
        }
      }
      return errorMessage;
    }
  }
};
</script>
