<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <WarnungMeldung
      :message="warnungtext"
      :visible="warnungvisible"
    />
    <v-card
      class="mx-auto my-12"
      max-width="1000"
    >
      <v-card-title>
        Themen bearbeiten
      </v-card-title>
      <div v-if="themen_data">
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
          <v-data-table
            :search="search"
            :headers="headers"
            :items="getData"
            :items-per-page="10"
            class="elevation-1"
            :loading="getloading"
          >
            <template #[`item.freigegeben`]="{ item }">
              <v-icon>
                {{ item.freigegeben ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
              </v-icon>
            </template>

            <template #[`item.sortactions`]="{ item }">
              <v-row>
                <v-btn
                  :disabled="error != null || item.sortorder == 1 || veraenderbar"
                  icon
                  @click="itemup(item)"
                >
                  <v-icon small>
                    mdi-arrow-up
                  </v-icon>
                </v-btn>
                <v-btn
                  :disabled="error != null || item.sortorder == getData.length || veraenderbar"
                  icon
                  @click="itemdown(item)"
                >
                  <v-icon small>
                    mdi-arrow-down
                  </v-icon>
                </v-btn>
              </v-row>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-row>
                <v-btn
                  icon
                  @click="editItem(item)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  :disabled="veraenderbar || error != null"
                  icon
                  @click="openDialog(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-row>
              <DialogBestaetigen
                :id="delid"
                :visible="dialogVisible"
                titel="Thema löschen"
                :frage="delfrage"
                @bestaetigen="deleteThema"
                @close="dialogVisible = false"
              />
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions v-if="!isaktiverZeitraum">
          <v-container>
            <v-row>
              <div class="flex-grow-1" />
              <v-btn
                :loading="isSaving"
                text
                color="primary"
                style="margin:auto"
                @click="themahinzufuegen()"
              >
                Hinzufügen
                <v-icon large>
                  mdi-send
                </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </div>
      <v-card-text v-else>
        <div class="text-center progress-circular-container">
          <v-progress-circular
            size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue";
import api from "@/utils/api";
export default {
  components: {
    DialogBestaetigen,
    FehlerMeldung,
    WarnungMeldung
  },
  props: {
    currentDate: {
      type: String,
      required: false,
      default: String
    }
  },
  data() {
    return {
      search: "",
      isSaving: false,
      warnungvisible: false,
      warnungtext:
        "Achtung! Themen können nur bearbeitet werden, wenn es gerade keinen aktiven Zeitraum gibt.",
      error: null,
      headers: [
        { text: "SortOrder", value: "sortorder" },
        { text: "Id", value: "id", sortable: false },
        { text: "Thema", value: "thema", sortable: false },
        { text: "Freigegeben", value: "freigegeben", sortable: false },
        { text: "Gewichtung", value: "gewichtung", sortable: false },
        { text: "SortAction", value: "sortactions", sortable: false },
        { text: "Aktionen", value: "actions", sortable: false }
      ],
      anzahlbewertungen: 0,
      delfrage: " ",
      delid: -1,
      dialogVisible: false,
      themen: null,
      themen_data: null,
      loading: true,
      isaktiverZeitraum: null
    };
  },

  computed: {
    getData() {
      return this.themen_data;
    },
    getloading() {
      return this.loading;
    },
    veraenderbar() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.warnungvisible = this.isaktiverZeitraum;
      return this.isaktiverZeitraum;
    }
  },
  mounted() {
    //bekommt den aktuelle Zeitraum
    api
      .get(`/api/info/zeitraum`)
      .then(response => {
        if (
          (response && response.punktevergabe) ||
          (response && response.themenvergabe)
        ) {
          this.isaktiverZeitraum = true;
        } else this.isaktiverZeitraum = false;
      })
      .catch(error => (this.error = error));
    this.updateThemen();
  },
  methods: {
    itemup(item){
      const url = `/api/thema/${item.id}/down`;
      this.isSaving = true
      api
        .put(url)
        .then(() => {
          this.isSaving = false
          this.updateThemen();
        })
        .catch(error =>{
          this.isSaving = false
          this.error = error
        });
    },
    itemdown(item){
      const url = `/api/thema/${item.id}/up`;
      this.isSaving = true
      api
        .put(url)
        .then(() => {
          this.isSaving = false
          this.updateThemen();
        })
        .catch(error =>{
          this.isSaving = false
          this.error = error
        });
    },
    themahinzufuegen(){
      this.$router.push({ name: `themahinzufuegen`});
    },
    editItem(Item) {
      this.$router.push({ name: `themabearbeiten`, params: { id: Item.id } });
    },
    openDialog(item) {
      this.delfrage = "Thema " + item.thema + " löschen?";
      this.delid = item.id;
      this.dialogVisible = true;
    },
    deleteThema(id) {
      if (id) {
        const url = `/api/thema/${id}`;
        api
          .del(url)
          .then(() => {
            this.updateThemen();
          })
          .catch(error => (this.error = error));
      }
      this.dialogVisible = false;
    },
    //aktualisiert die Liste
    updateThemen() {
      const url = `/api/themen/`;
      this.loading = true;
      api
        .get(url)
        .then(response => {
          const themen_table = [];
          response.themen.forEach(thema => {
            themen_table.push({
              id: thema.id,
              thema: thema.thema,
              anzahlfragen: thema.fragen.length,
              sortorder: thema.sortorder,
              freigegeben: thema.freigegeben,
              gewichtung: thema.gewichtung
            });
          });
          this.themen_data = themen_table;
          this.themen = response.themen;
          this.loading = false;
        })
        .catch(error => {
          this.error = error;
          this.loading = false;
          if (this.themen_data == null) {
            this.themen_data = [];
          }
        });
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
