<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <WarnungMeldung
      :message="warnungtext"
      :visible="warnungvisible"
    />
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        Thema bearbeiten
      </v-card-title>
      <div v-if="ready">
        <div v-if="fertig.geloescht || fertig.gespeichert">
          <v-card-text v-if="fertig.geloescht">
            Das Thema wurde erforlgreich gelöscht!
          </v-card-text>
          <v-card-text v-else>
            Das Thema wurde gespeichert!
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              text
              color="primary"
              @click="weiter"
            >
              Weiter
              <v-icon large>
                mdi-send
              </v-icon>
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text>
            <v-form>
              <v-checkbox
                v-model="checkbox"
                label="Freigeben"
                required
                :readonly="veraenderbar"
              />
              <v-textarea
                id="titel"
                ref="titel"
                v-model="form.titel.input"
                auto-grow
                rows="1"
                :label="'Name des Themas'"
                autocomplete="none"
                type="text"
                :readonly="veraenderbar"
                :error-messages="form.titel.errormessage"
              />
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      id="sortorder"
                      ref="sortorder"
                      v-model="form.sortorder.input"
                      :error="form.sortorder.error"
                      :error-messages="form.sortorder.errormessage"
                      :readonly="veraenderbar"
                      label="Sortorder"
                      type="number"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      id="gewichtung"
                      ref="gewichtung"
                      v-model="form.gewichtung.input"
                      :error="form.gewichtung.error"
                      :error-messages="form.gewichtung.errormessage"
                      :readonly="veraenderbar"
                      label="Gewichtung"
                      type="number"
                    />
                  </v-col>
                </v-row>
              </v-container>

              <v-container fluid>
                <div
                  v-for="(frage, index) in form.fragen"
                  :key="index"
                >
                  <v-row>
                    <v-textarea
                      v-model="form.fragen[index]"
                      :readonly="veraenderbar"
                      auto-grow
                      rows="1"
                      :label="'Frage ' + (index + 1)"
                      autocomplete="nome"
                      type="text"
                    >
                      {{ frage }}
                    </v-textarea>
                    <v-btn
                      :disabled="anzahlFragen == 1 || veraenderbar"
                      class="mx-2"
                      fab
                      small
                      color="indigo"
                      @click="FrageLoeschen(index)"
                    >
                      <v-icon dark>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      color="indigo"
                      :disabled="veraenderbar"
                      @click="FrageHinzufuegen(index)"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-row>
                </div>
                <v-row>
                  <div class="flex-grow-1" />
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    color="indigo"
                    :disabled="veraenderbar"
                    @click="FrageHinzufuegen(anzahlFragen)"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :dissabled="isSaving"
              text
              color="primary"
              @click="goback"
            >
              <v-icon large>
                mdi-arrow-left
              </v-icon>
              Zurück
            </v-btn>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="deleteDisabled || isSaving || error != null"
              text
              color="primary"
              @click="openDialog"
            >
              Löschen
              <v-icon large>
                mdi-delete
              </v-icon>
            </v-btn>
            <DialogBestaetigen
              :id="id"
              :visible="dialogVisible"
              titel="Thema löschen"
              :frage="delfrage"
              @bestaetigen="deleteThema"
              @close="dialogVisible = false"
            />
            <v-btn
              :disabled="submitDisabled || error != null"
              :loading="isSaving"
              text
              color="primary"
              @click="save"
            >
              Speichern
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
      <v-card-text v-else>
        <div class="text-center progress-circular-container">
          <v-progress-circular
            size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";

import api from "@/utils/api";
export default {
  components: {
    DialogBestaetigen,
    FehlerMeldung,
    WarnungMeldung
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      form: {
        titel:{
          input: "",
          errormessage: ""
        },
        fragen: ["", "", ""],
        sortorder: {
          input: 0,
          errormessage: "",
          error: false
        },
        gewichtung: {
          input: 0,
          errormessage: "",
          error: false
        }
      },
      checkbox: false,
      Thema: null,
      isSaving: false,
      error: null,
      isaktiverZeitraum: null,
      themen: [],
      fertig: {
        erforlgreich: false,
        geloescht: false,
        gespeichert: false,
      },
      ready: false,
      delfrage: "",
      dialogVisible: false,
      warnungvisible: false,
      warnungtext:
        "Achtung! Thema kann nur bearbeitet werden, wenn es gerade keinen aktiven Zeitraum gibt."
    };
  },
  computed: {
    submitDisabled() {
      if (
        this.isSaving ||
        this.checkTitel ||
        this.checkFragen ||
        this.veraenderbar ||
        this.checkSortOrder ||
        this.checkGewichtung()
      ) {
        return true;
      }
      if (this.form.titel.input == "") return true;
      if (this.veraenderung) return true;
      return false;
    },
    //kontroliert ob der Benutzer ein Feld verändert hat
    veraenderung() {
      if (this.form.titel.input != this.Thema.thema) return false;
      if (this.form.fragen.length != this.Thema.fragen.length) return false;
      let ret = true;
      this.form.fragen.forEach((fr, index) => {
        if (this.Thema.fragen[index] && this.Thema.fragen[index].frage != fr) {
          ret =  false;
        }
      });
      if(!ret) return false
      if(this.form.sortorder.input != this.Thema.sortorder) return false
      if(this.form.gewichtung.input != this.Thema.gewichtung) return false
      if(this.checkbox != this.Thema.freigegeben) return false
      return true;
    },
    deleteDisabled() {
      if (this.veraenderbar) return true;
      if (this.isSaving) return true;
      return false;
    },
    //Kontrolliert ob der Benutzer den Titel verändert hat
    checkTitel() {
      if (this.Thema && this.form.titel.input == this.Thema.thema) return false;
      if(this.themen.includes(this.form.titel.input)){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.titel.errormessage = "Titel gibt es schon"
        return true
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.titel.errormessage = ""
        return false
      }
    },
    //Kontrolliert ob der Benutzer den eine Frage verändert hat
    checkFragen() {
      let ret = false;
      if (this.form.fragen) {
        this.form.fragen.forEach(frage => {
          if (frage == "") {
            ret = true;
          }
        });
      }
      return ret;
    },
    checkSortOrder(){
      if((this.form.sortorder.input < 1 || this.form.sortorder.input > this.themen.length) && this.ready ){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.sortorder.errormessage = "SortOrder muss zwischen 1 und " + this.themen.length + " sein!"
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.sortorder.error = false
        return true
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.sortorder.errormessage = ""
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.sortorder.error = false
        return false
      }
    },
    anzahlFragen() {
      if (this.form.fragen) {
        return this.form.fragen.length;
      }
      return 0;
    },

    veraenderbar() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.warnungvisible = this.isaktiverZeitraum;
      return this.isaktiverZeitraum;
    },
    erforlgreichAbgeschlosse() {
      if (this.fertig.erforlgreich) return true;
      return false;
    },
  },
  mounted() {
    /*holt sich das Thema dessen Id übergeben wurde
    Bei Fehler wird er angezeigt und Thema/Fragen sind leer*/
    const url = `/api/thema/${this.id}`;
    api
      .get(url)
      .then(response => {
        this.checkbox = response.themen[0].freigegeben
        this.form.gewichtung.input = response.themen[0].gewichtung
        this.form.sortorder.input = response.themen[0].sortorder
        this.Thema = response.themen[0];
        this.delfrage = "Thema " + this.Thema.thema + " löschen?";
        const temp_fragen = [];
        this.Thema.fragen.forEach(fr => {
          temp_fragen.push(fr.frage);
        });
        this.form.fragen = temp_fragen;
        this.form.titel.input = response.themen[0].thema;
        //holt Zeitraum
        api
          .get(`/api/info/zeitraum`)
          .then(response => {
            if (
              (response && response.punktevergabe) ||
              (response && response.themenvergabe)
            ) {
              this.isaktiverZeitraum = true;
            } else this.isaktiverZeitraum = false;
            //holt sich die Themen und speichert sich die namen des Themen ab
            const url2 = `/api/themen`;
            api
              .get(url2)
              .then(response => {
                const themen_titel = [];
                response.themen.forEach(thema => {
                  themen_titel.push(thema.thema);
                });
                this.themen = themen_titel;
                this.ready = true;
              })
              .catch(error => {
                this.error = error;
                this.ready = true;
              });
          })
          .catch(error => {
            this.error = error;
            this.ready = true;
          });
      })
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  },
  methods: {
    /*speichert das veränderte Thema ab*/
    async save() {
      if (!this.veraenderbar) {
        this.isSaving = true;
        this.error = null;
        const Fragen = [];
        this.form.fragen.forEach((frage, index) => {
          const Frage = {
            id: index + 1,
            frage: frage
          };
          Fragen.push(Frage);
        });
        const data = {
          fragen: Fragen,
          thema: this.form.titel.input,
          id: this.Thema.id,
          sortorder: this.form.sortorder.input,
          freigegeben: this.checkbox,
          gewichtung: this.form.gewichtung.input
        };
        const url = `/api/thema/${this.id}`;
        const postData = {
          thema: data
        };
        api
          .put(url, postData)
          .then(() => {
            this.isSaving = false;
            this.fertig.gespeichert = true;
          })
          .catch(error => {
            this.isSaving = false;
            this.error = error;
          });
      }
    },
    openDialog() {
      this.dialogVisible = true;
    },
    //Löscht den Thema aus der DB, dessen Id übergeben wurde
    deleteThema(id) {
      if (id) {
        this.error = null;
        this.isSaving = true;
        const url = `/api/thema/${id}`;
        api
          .del(url)
          .then(() => {
            this.isSaving = false;
            this.fertig.erforlgreich = true;
            this.fertig.geloescht = true;
          })
          .catch(error => {
            this.isSaving = false;
            this.error = error;
          });
      }
      this.dialogVisible = false;
    },
    goback() {
      this.$router.go(-1);
    },
    FrageHinzufuegen(index) {
      this.form.fragen.splice(index, 0, "");
    },
    FrageLoeschen(index) {
      this.form.fragen.splice(index, 1);
    },
    weiter() {
      this.$router.push({ name: "themenbearbeiten" });
    },
    checkGewichtung(){
      if(this.form.gewichtung.input == null || this.form.gewichtung.input <= 0){
        this.form.gewichtung.error = true;
        this.form.gewichtung.errormessage = "Gewichtung muss größer als 0 sein";
      }else{
        this.form.gewichtung.error = false;
        this.form.gewichtung.errormessage = "";
      }
      return this.form.gewichtung.input <= 0;
    }
  }
};
</script>
<style scoped>
  .p-rot-text{
    color:red
  }
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
