<template>
  <v-dialog
    :value="visible"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar
        dark
        dense
        class="primary"
      >
        <v-toolbar-title>
          Mitarbeiter
        </v-toolbar-title>
      </v-toolbar>

      <v-container fluid>
        <v-row>
          <v-col
            v-for="mitarbeiter in items"
            :key="mitarbeiter.id"
            cols="6"
            xs="6"
            md="3"
            lg="2"
            no-gutters
          >
            <v-card
              @click="onClickMitarbeiter({selectedPerson: mitarbeiter})"
            >
              <v-list-item class="grow">
                <v-list-item-avatar>
                  <v-img
                    v-if="mitarbeiter.bild"
                    class="elevation-6"
                    :src="mitarbeiter.bild"
                    @error="defImg(mitarbeiter)"
                  />
                  <v-icon
                    v-else
                    :size="50"
                  >
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ mitarbeiter.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions
        fixed
      >
        <v-spacer />
        <v-btn
          @click="$emit('close')"
        >
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    items: {
      type: Array,
      required: true,
      default: Array
    }
  },

  data () {
    return {}
  },

  methods: {
    onClickMitarbeiter ({ selectedPerson }) {
      this.$emit('selected', selectedPerson)
    },
    defImg(item){
      item.bild = null
    }
  }
}
</script>
