<template>
  <div>
    <FehlerMeldung :error="error" />
    <div
      v-if="!ready"
      class="text-center progress-circular-container"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </div>
    <div v-else-if="error && themen.length == 0">
      <v-card
        class="mx-auto my-12"
        max-width="800"
      >
        <v-card-title>
          Bewertung
        </v-card-title>
        <v-card-text>
          Konnte nicht zum Bewerten wechseln!
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$router.push({ path: `/themen` })">
            <v-icon>
              mdi-arrow-left
            </v-icon>
            Zurück
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </div>
    <v-stepper
      v-if="!isLoading"
      v-model="e1"
      light
      class="align-md-center"
    >
      <v-stepper-header>
        <template v-for="n in fragen.length">
          <v-stepper-step
            :key="`${n}-step`"
            :editable="e1 >= n"
            :complete="e1 > n"
            :step="n"
          />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="n in fragen.length"
          :key="`${n}-content`"
          :step="n"
        >
          <v-card
            class="mx-auto my-12"
            max-width="640"
          >
            <v-card-title>
              {{ themen[0].thema }}
            </v-card-title>
            <v-card-text>
              <div>
                {{ getFrage(n) }}
              </div>
              <v-col cols="12">
                <v-row>
                  <v-autocomplete
                    ref="person1"
                    v-model="person1"
                    :items="getUnselectedItems(person1)"
                    autocomplete="off"
                    autofocus
                    auto-select-first
                    hide-selected
                    color="blue-grey lighten-2"
                   :label="getlabel3"
                    item-text="name"
                    item-value="id"
                    append-outer-icon="mdi-playlist-plus"
                    @click:append-outer="
                      showOverview({
                        items: getUnselectedItems(person1),
                        person: 'person1',
                      })
                    "
                  >
                    <template #selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="person1 = null"
                      >
                        <v-avatar left>
                          <v-img
                            v-if="data.item.bild"
                            :src="data.item.bild"
                            @error="defImg(data.item)"
                          />
                          <v-icon
                            v-else
                            :size="30"
                          >
                            mdi-account-circle
                          </v-icon>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template #item="data">
                      <template v-if="(typeof data.item) !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <img
                            v-if="data.item.bild"
                            :src="data.item.bild"
                            @error="defImg(data.item)"
                          >
                          <v-icon
                            v-else
                            :size="40"
                          >
                            mdi-account-circle
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="data.item.name" />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>

                <v-row>
                  <v-autocomplete
                    ref="person2"
                    v-model="person2"
                    :items="getUnselectedItems(person2)"
                    autocomplete="off"
                    auto-select-first
                    hide-selected
                    color="blue-grey lighten-2"
                    :label="getlabel2"
                    item-text="name"
                    item-value="id"
                    append-outer-icon="mdi-playlist-plus"
                    @click:append-outer="
                      showOverview({
                        items: getUnselectedItems(person2),
                        person: 'person2',
                      })
                    "
                  >
                    <template #selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="person2 = null"
                      >
                        <v-avatar left>
                          <v-img
                            v-if="data.item.bild"
                            :src="data.item.bild"
                            @error="defImg(data.item)"
                          />
                          <v-icon
                            v-else
                            :size="30"
                          >
                            mdi-account-circle
                          </v-icon>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template #item="data">
                      <template v-if="(typeof data.item) !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <img
                            v-if="data.item.bild"
                            :src="data.item.bild"
                            @error="defImg(data.item)"
                          >
                          <v-icon
                            v-else
                            :size="40"
                          >
                            mdi-account-circle
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="data.item.name" />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>

                <v-row>
                  <v-autocomplete
                    ref="person3"
                    v-model="person3"
                    :items="getUnselectedItems(person3)"
                    autocomplete="off"
                    auto-select-first
                    hide-selected
                    color="blue-grey lighten-2"
                    :label="getlabel1"
                    item-text="name"
                    item-value="id"
                    append-outer-icon="mdi-playlist-plus"
                    @click:append-outer="
                      showOverview({
                        items: getUnselectedItems(person3),
                        person: 'person3',
                      })
                    "
                  >
                    <template #selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="person3 = null"
                      >
                        <v-avatar left>
                          <v-img
                            v-if="data.item.bild"
                            :src="data.item.bild"
                            @error="defImg(data.item)"
                          />
                          <v-icon
                            v-else
                            :size="30"
                          >
                            mdi-account-circle
                          </v-icon>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template #item="data">
                      <template v-if="(typeof data.item) !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <img
                            v-if="data.item.bild"
                            :src="data.item.bild"
                            @error="defImg(data.item)"
                          >
                          <v-icon
                            v-else
                            :size="40"
                          >
                            mdi-account-circle
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="data.item.name" />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn
                v-if="n < fragen.length"
                :disabled="submitDisabled"
                text
                color="primary"
                @click="nextStep(n)"
              >
                Weiter
                <v-icon large>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                :disabled="submitDisabled"
                :loading="buttonLoading"
                text
                color="primary"
                @click="nextStep(n)"
              >
                Abschliessen
                <v-icon large>
                  mdi-send
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
      <DialogMitarbeiter
        :visible="dialogVisible"
        :items="dialogItems"
        @selected="onClickMitarbeiter"
        @close="dialogVisible = false"
      />
    </v-stepper>
  </div>
</template>

<script>
import api from "@/utils/api";
import Vue from "vue";

import DialogMitarbeiter from "@/components/Dialoge/DialogMitarbeiter.vue";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";

export default {
  components: {
    DialogMitarbeiter,
    FehlerMeldung
  },

  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      e1: 1,
      steps: 3,
      antworten: [],
      person1: null,
      person2: null,
      person3: null,
      people: [],
      isLoading: true,
      dialogVisible: false,
      dialogItems: [],
      dialogSelectedPerson: null,
      error: null,
      themen: null,
      ready: false,
      buttonLoading: false
    };
  },

  computed: {
    
    getlabel1(){
      return  (this.themen[0].gewichtung * 1)+ " Punkte"
    },
    getlabel2(){
      return  (this.themen[0].gewichtung * 2)+ " Punkte"
    },
    getlabel3(){
      return  (this.themen[0].gewichtung * 3)+ " Punkte"
    },
    fragen() {
      // let results = fragenDaten.results
      let results = [];
      const themen = this.themen;
      // const themen = this.themen.results.filter(item => item.id == this.id)
      if (themen && themen.length > 0 && themen[0].fragen) {
        results = themen[0].fragen;
      }
      const fragen = [];
      results.forEach(element => {
        fragen.push(element.frage);
      });
      return fragen;
    },
    thema() {
      // const themen = this.themen.results.filter(item => item.id == this.id)
      const themen = this.themen;
      if (themen && themen.length > 0 && themen[0].thema) {
        return themen[0].thema;
      }
      return "Themen";
    },
    submitDisabled() {
      if (!this.person1 || !this.person2 || !this.person3) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    e1(val) {
      this.restoreAntworten(val);
    }
  },

  mounted() {
    this.$store.commit("title", this.thema);
    const url = `/api/thema/${this.id}`;
    //hold das Thema
    api
      .get(url)
      .then(response => {
        this.themen = response.themen;
        this.setPunkte();

        //hold die Mitarbeiter
        api
          .get("/api/mitarbeiter")
          .then(response => {
            this.people = response.mitarbeiter;
            //hold die Bewertung
            api
              .get(`/api/thema/${this.themen[0]._id}/bewertung`)
              .then(response => {
                this.isLoading = false;
                this.ready = true;
                if (response.bewertungen && response.bewertungen.length > 0) {
                  this.$router.push({
                    name: "zusammenfassung",
                    params: { id: this.id }
                  });
                }
                //Bewertung
              })
              .catch(error => {
                this.ready = true;
                this.error = error;
                this.isLoading = false;
              });
            //Mitarbeiter
          })
          .catch(error => {
            this.ready = true;
            this.error = error;
            this.isLoading = false;
          });
        //Thema
      })
      .catch(error => {
        this.error = error;
        this.themen = [];
        this.isLoading = false;
        this.ready = true;
      });
  },

  methods: {
    showOverview({ items, person }) {
      this.dialogItems = items;
      this.dialogSelectedPerson = person;
      this.dialogVisible = true;
    },
    onClickMitarbeiter(selectedPerson) {
      Vue.set(this, this.dialogSelectedPerson, selectedPerson.id);
      this.dialogVisible = false;
    },
    nextStep(n) {
      if (n === this.fragen.length) {
        this.antworten[n - 1] = [this.person1, this.person2, this.person3];
        this.storeAntworten();
      } else {
        // antworten speichern
        this.antworten[n - 1] = [this.person1, this.person2, this.person3];
        this.person1 = null;
        this.person2 = null;
        this.person3 = null;
        this.e1 = n + 1;
      }
    },
    storeAntworten() {
      this.buttonLoading = true;
      this.error = null;
      const antworten2 = [];
      this.antworten.forEach((antwort, index) => {
        const frage = {
          frage: this.themen[0].fragen[index].frage,
          punkte: [
            {
              id: antwort[0],
              name: this.people.filter(item => item.id === antwort[0])[0].name,
              punkte: 3 * this.themen[0].gewichtung
            },
            {
              id: antwort[1],
              name: this.people.filter(item => item.id === antwort[1])[0].name,
              punkte: 2 * this.themen[0].gewichtung
            },
            {
              id: antwort[2],
              name: this.people.filter(item => item.id === antwort[2])[0].name,
              punkte: 1 * this.themen[0].gewichtung
            }
          ]
        };
        antworten2.push(frage);
      });
      const url = `/api/thema/${this.id}/bewertung`;
      const postData = {
        thema: this.themen[0]._id,
        thema_id: this.id,
        thema_name: this.themen[0].thema,
        bewertung: antworten2,
        gewichtung: this.themen[0].gewichtung
      };
      api
        .post(url, postData)
        .then(() => {
          this.buttonLoading = false;
          this.$router.push({
            name: "zusammenfassung",
            params: { id: this.id }
          });
        })
        .catch(error => {
          this.buttonLoading = false;
          this.error = error;
        });
    },
    getFrage(n) {
      return this.fragen[parseInt(n) - 1];
    },
    restoreAntworten(n) {
      this.person1 = null;
      this.person2 = null;
      this.person3 = null;

      if (this.antworten && n && this.antworten.length >= n) {
        if (this.antworten[n - 1] && this.antworten[n - 1][0]) {
          this.person1 = this.antworten[n - 1][0];
        }
        if (this.antworten[n - 1] && this.antworten[n - 1][1]) {
          this.person2 = this.antworten[n - 1][1];
        }
        if (this.antworten[n - 1] && this.antworten[n - 1][2]) {
          this.person3 = this.antworten[n - 1][2];
        }
      }
    },
    getUnselectedItems(selectedItem) {
      // sich selbst ausschliessen
      const MitarbeiterIch = Number(localStorage.getItem("mitarbeiter_id"));
      let selectedIds = [
        this.person1,
        this.person2,
        this.person3,
        MitarbeiterIch
      ];
      selectedIds = selectedIds.filter(item => item !== selectedItem);
      return this.people.filter(item => !selectedIds.includes(item.id));
    },
    defImg(item){
      item.bild = null
    },
    setPunkte(){
      this.label1 = this.themen[0].gewichtung + " Punkte";
      this.label2 = this.themen[0].gewichtung * 2  + " Punkte";
      this.label3 = this.themen[0].gewichtung * 3 + " Punkte";

    },
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
