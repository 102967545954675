<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <WarnungMeldung
      :message="warnungtext"
      :visible="warnungvisible"
    />
    <v-card
      class="mx-auto justify-sm-space-between"
      max-width="800"
    >
      <div v-if="ready">
        <v-container
          class="pa-2"
          fluid
        >
          <!--Falls keine Themen vorhanden sind-->
          <template v-if="themen.length == 0">
            <v-card-title>
              Themen
            </v-card-title>
            <v-divider />
            <v-card-text>
              Keine Themen gefunden
            </v-card-text>
          </template>
          <v-row v-else>
            <template v-for="(item, i) in themen">
              <v-col
                v-if="!item.disabled"
                :key="i"
                cols="6"
              >
                <v-card
                  :disabled="isAktiverZeitraum"
                  :color="themenColors[(i % 7) + 1]"
                  dark
                  :to="{ name: 'thema', params: { id: item.id } }"
                >
                  <v-card-text class="white--text">
                    <div class="headline mb-2">
                      {{ item.thema }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                v-else
                :key="i"
                cols="6"
              >
                <v-card
                  :color="themenColors[(i % 7) + 1]"
                  dark
                >
                  <v-card-text class="white--text">
                    <div class="headline mb-5 text-center mt-5">
                      ?
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </div>
      <div
        v-else
        class="text-center progress-circular-container"
      >
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue";
import api from "@/utils/api";
export default {
  components: {
    FehlerMeldung,
    WarnungMeldung
  },
  props: {
    themenColors: {
      type: Object,
      required: false,
      default: Object
    },
    currentDate: {
      type: String,
      required: false,
      default: String
    }
  },

  data: () => ({
    bereitsAbgestimmt: true,
    ready: false,
    themen: [],
    zeitraum: null,
    isaktiverzeitraum: null,
    error: null,
    warnungvisible: false,
    warnungZeitraum:
      "Achtung! Es kann nur abgestimmt werden, wenn es gerade einen aktiven Zeitraum für die Punktevergabe gibt.",
    warnungBereitsAbgestimmt: "Achtung! Sie haben bereits abgestimmt.",
    warnungtext: ""
  }),
  computed: {
    isAktiverZeitraum() {
      //Ist Aktiver Zeitraum?
      if (this.bereitsAbgestimmt == true) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.warnungtext = this.warnungBereitsAbgestimmt;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.warnungvisible = true;
        return true;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.warnungtext = this.warnungZeitraum;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.warnungvisible = !this.isaktiverzeitraum;
        return !this.isaktiverzeitraum;
      }
    }
  },
  mounted() {
    //Die Themen holen
    const url = `/api/themen/aktuell`;
    api
      .get(url)
      .then(response => {
        this.themen = response.aktiveThemen;
        //Holt den Zeitraum
        api
          .get("/api/themenzeitraum/aktiv")
          .then(response => {
            if (response && response.themenzeitraum != null) {
              this.isaktiverzeitraum = true;
            } else {
              this.isaktiverzeitraum = false;
            }
            api
              .get("/api/abschluss/bereitsabgestimmt")
              .then(response => {
                this.ready = true;
                if (response.bereitsabgestimmt >= 1) {
                  this.bereitsAbgestimmt = true;
                } else {
                  this.bereitsAbgestimmt = false;
                }
              })
              .catch(error => {
                this.error = error;
                this.ready = true;
              });
          })
          .catch(error => {
            this.error = error;
            this.ready = true;
          });
      })
      .catch(error => {
        this.error = error;
        this.ready = true;
        this.themen = [];
      });
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
