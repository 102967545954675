<template>
  <div>
    <v-autocomplete
      ref="person"
      v-model="person"
      :items="getUnselectedItems(person)"
      :autocomplete="_uid"
      auto-select-first
      hide-selected
      color="blue-grey lighten-2"
      item-text="name"
      item-value="id"
      :label="label"
    >
      <template #selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="person = null"
        >
          <v-avatar left>
            <v-img
              v-if="data.item.bild"
              :src="data.item.bild"
              @error="defImg(data.item)"
            />
            <v-icon
              v-else
              :size="30"
            >
              mdi-account-circle
            </v-icon>
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template #item="data">
        <template v-if="(typeof data.item )!== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-avatar>
            <img
              v-if="data.item.bild"
              :src="data.item.bild"
              @error="defImg(data.item)"
            >
            <v-icon
              v-else
              :size="50"
            >
              mdi-account-circle
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.name" />
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    },
    people: {
      type: Array,
      required: false,
      default: Array
    },
    excludeIds: {
      type: Array,
      required: false,
      default: Array
    },
    initial: {
      type: Number,
      required: false,
      default: null
    },
    label:{
      type: String,
      required: false,
      default: String
    }
  },

  data() {
    return {
      person: null
    };
  },

  watch: {
    initial: {
      handler: function(val) {
        if (val && !this.person) {
          this.person = val;
        }
      },
      immediate: true
    },
    person: {
      handler: function(val) {
        //this.person = null
        this.$emit("select", { id: this.id, value: val });
      },
      immediate: true
    },
    clear: function() {
      this.person = null;
    }
  },

  methods: {
    getUnselectedItems(selectedItem) {
      // sich selbst ausschliessen
      const MitarbeiterIch = Number(localStorage.getItem("mitarbeiter_id"));
      let selectedIds = [...this.excludeIds, MitarbeiterIch];
      selectedIds = selectedIds.filter(item => item !== selectedItem);
      return this.people.filter(item => !selectedIds.includes(item.id));
    },
    defImg(item){
      item.bild = null
    }
  }
};
</script>
