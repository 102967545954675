<template>
  <v-card
    class="mx-auto my-12"
    max-width="800"
  >
    <v-card-title>
      Übersicht
    </v-card-title>
    <v-card-text>
      <v-simple-table
        v-if="ready"
        class="row-pointer"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                Zeitraum
              </th>
              <th class="text-left">
                Anzahl Themen
              </th>
              <th class="text-left">
                Anzahl Bewertungen
              </th>
            </tr>
          </thead>
          <tbody v-if="daten && themenzeitraume">
            <tr
              v-for="(th, index) in daten"
              :key="index"
              @click="opendetail(daten[index])"
            >
              <td>
                Von: {{ daten[index].zeitraum.beginn }}
                Bis: {{ daten[index].zeitraum.ende }}
              </td>
              <td>
                {{ daten[index].Themen.length }}
              </td>
              <td>
                {{ daten[index].anzahlbew }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div
        v-else
        class="text-center progress-circular-container"
      >
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    themenzeitraume: {
      type: Array,
      required: true
    },
    ready: {
      type: Boolean,
      required: true
    },
    daten:{
      type: Array,
      required: true
    }
  },
  data: () => ({}),
  computed: {},
  methods: {
    opendetail(data) {
      this.$router.push({ path: `/bewertungen/${data.zeitraum._id}` });
    }
  }
};
</script>
