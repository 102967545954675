<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        Verwaltung bearbeiten
      </v-card-title>
      <div v-if="gespeichert">
        <v-card-text>
          Verwaltung hinzugefügt!
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="gespeichert = false"
          >
            Weiter
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text v-if="verwaltunglist">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
          <v-data-table
            :search="search"
            :headers="headers"
            :items="getData"
            :items-per-page="10"
            class="elevation-1"
            :loading="getloading"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon
                small
                @click="openDialog(item)"
              >
                mdi-delete
              </v-icon>
              <DialogBestaetigen
                :id="delid"
                :visible="dialogVisible"
                titel="Admin"
                :frage="delfrage"
                @bestaetigen="deleteEintrag"
                @close="dialogVisible = false"
              />
            </template>
          </v-data-table>
          <v-container v-if="!hideinput">
            <v-row>
              <AutocompleteMitarbeiter
                :clear="clear"
                label="Mitarbeiter"
                :people="people"
                :exclude-ids="getAdminVerwaltungIds"
                @select="selectMitarbeiter"
              />
              <div class="flex-grow-1" />

              <v-btn
                :disabled="submitDisabled"
                :loading="isSaving"
                text
                color="primary"
                style="margin:auto"
                @click="save(getSelected)"
              >
                Hinzufügen
                <v-icon large>
                  mdi-send
                </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center progress-circular-container">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import AutocompleteMitarbeiter from "@/components/Autocomplete/AutocompleteAddMitarbeiter.vue";
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import api from "@/utils/api";
export default {
  components: {
    AutocompleteMitarbeiter,
    DialogBestaetigen,
    FehlerMeldung
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      search: "",
      isSaving: false,
      error: null,
      hideinput: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Aktionen", value: "actions", sortable: false }
      ],
      delfrage: "",
      delid: -1,
      dialogVisible: false,
      verwaltunglist: null,
      adminlist: [],
      people: [],
      selected: {},
      loading: true,
      clear: false,
      gespeichert: false
    };
  },

  computed: {
    getData() {
      return this.verwaltunglist;
    },
    submitDisabled() {
      if (this.selected) return false;
      else return true;
    },
    getSelected() {
      return this.selected;
    },
    getAdminVerwaltungIds(){
      const ret = [];
      this.adminlist.forEach(eintrag => {
        ret.push(eintrag.id);
      });
      this.verwaltunglist.forEach(eintrag => {
        ret.push(eintrag.id);
      });
      return ret;
    },
    getloading() {
      return this.loading;
    }
  },
  mounted() {
    this.updateList();
    this.$store.commit("title", "Verwaltungliste bearbeiten");
    const url_Mitarbeiter = "/api/blacklist/mitarbeiter"
    const url_Admin = "/api/admin/"
    api
      .get(url_Mitarbeiter)
      .then(response => {
        this.people = response.mitarbeiter
        this.adminlist = response.mitarbeiter
        api
          .get(url_Admin)
          .then(response => (this.adminlist = response.adminlist))
          .catch(error => (this.error = error));
      }) 
      .catch(error => (this.error = error));

  },
  methods: {
    selectMitarbeiter({ value }) {
      this.selected = value;
    },
    save(id) {
      this.error = null;
      const url = `/api/verwaltung/`;
      const postData = {
        mitarbeiter: this.people.find(peo => peo.id == id)
      };
      api
        .post(url, postData)
        .then(() => {
          this.clear = !this.clear;
          this.gespeichert = true;
          this.updateList();
        })
        .catch(error => {
          this.error = error;
        });
    },
    openDialog(item) {
      this.delfrage = "Verwaltung " + item.name + " löschen?";
      this.delid = item.id;
      this.dialogVisible = true;
    },
    deleteEintrag(id) {
      this.dialogVisible = false;
      this.error = null;
      if (id) {
        const url = `/api/verwaltung/${id}`;
        api
          .del(url)
          .then(() => {
            this.updateList();
          })
          .catch(error => {
            this.error = error;
          });
      }
    },
    updateList() {
      const url2 = `/api/verwaltung/`;
      this.loading = true;
      this.hideinput = false;
      api
        .get(url2)
        .then(response => {
          this.verwaltunglist = response.verwaltung;
          this.loading = false;
        })
        .catch(error => {
          if (this.verwaltunglist == null) {
            this.verwaltunglist = [];
            this.hideinput = true;
          }
          this.loading = false;
          this.error = error;
        });
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
