<template>
  <v-dialog
    :value="visible"
    width="500"
    :retain-focus="false"
    persistent
  >
    <v-card>
      <v-toolbar
        dark
        dense
        class="primary"
      >
        <v-toolbar-title>
          Mitarbeiter Refresh
        </v-toolbar-title>
      </v-toolbar>
      <div v-if="!ready">
        <v-card-title>
          Mitarbeiter werden aktualisiert...
        </v-card-title>
        <div
          class="text-center progress-circular-container"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </div>
      <div v-else>
        <v-card-title v-if="error == null">
          <span
            style="white-space: pre-line"
          >
            Mitarbeiter wurden erfolgreich aktualisiert!
          </span>
        </v-card-title>
        <div v-else>
          <v-card-title>
            Mitarbeiter konnten nicht aktualisiert werden:
          </v-card-title>
          <v-card-text>
            {{ geterrormessage() }}
          </v-card-text>
        </div>
      </div>
      <v-divider />
      <v-card-actions fixed>
        <v-spacer />
        <v-btn
          :disabled="!ready"
          text
          color="primary"
          @click="close"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/utils/api";
export default {

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    refreshMitarbeiter: {
      type: Boolean,
      require: true
    }
  },

  data() {
    return {
      ready: false,
      error: null
    };
  },
  watch: {
    refreshMitarbeiter: function() {
      this.mitarbeiterrefresh()
    }
  },
  methods: {
    geterrormessage(){
      if(this.error){
        if(this.error.response.status >= 500){
          return "Ein unkontrollierter Fehler ist aufgetreten!";
        }else if (this.error.response.status >= 400) {
          if (this.error.response.data.message)
            return this.error.response.data.message;
          else return "Ein unkontrollierter Fehler ist aufgetreten!";
        }
      }else{
        return "keine Fehler wurde erkannt"
      }
    },
    //Beim bestätigen wird die Id übergeben, falls sie vorhanden ist
    onbestaetigt() {
      this.$emit("bestaetigen");
      //this.ready = false
    },
    close(){
      this.$emit('close')
      this.error = null
      this.ready = false
    },
    async mitarbeiterrefresh(){
      const refreshAPI = `/api/mitarbeiter/refresh`;
      try {
        await api.get(refreshAPI);
        this.ready = true
      } catch (err) {
        console.log(err)
        this.error = err
        this.ready = true
      }
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
