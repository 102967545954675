<template>
  <div>
    <FehlerMeldung :error="error" />
    <WarnungMeldung
      :message="warnungtext"
      :visible="warnungvisible"
    />
    <v-card
      max-width="800"
      class="mx-auto my-12"
    >
      <v-card-title>
        Thema hinzufügen
      </v-card-title>
      <div v-if="erstellt">
        <v-card-text>
          Das Thema wurde erforlgreich erstellt!
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="weiter()"
          >
            Weiter
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else-if="ready">
        <v-card-text>
          <v-form>
            <v-checkbox
              v-model="checkbox"
              label="Freigeben"
              required
            />
            <v-textarea
              v-model="titel"
              :readonly="veraenderbar"
              auto-grow
              rows="1"
              name="titel"
              :label="'Name des Themas'"
              autocomplete="none"
              type="text"
            />
            <p
              v-if="checkTitel"
              class="p-rot-text"
            >
              Titel gibt es schon!
            </p>
            
            <v-text-field
              ref="gewichtung"
              v-model="gewichtung"
              :readonly="veraenderbar"
              label="Gewichtung"
              type="number"
            />
            <p
              v-if="checkGewichtung"
              class="p-rot-text"
            >
              Gewichtung muss größer als 0 sein
            </p>
            <div
              v-for="(frage, index) in fragen"
              :key="index"
            >
              <v-textarea
                v-model="fragen[index]"
                :name="'frage' + (index+1)"
                :readonly="veraenderbar"
                auto-grow
                rows="1"
                :label="'Frage ' + (index + 1)"
                autocomplete="nome"
                type="text"
              >
                {{ frage }}
              </v-textarea>
            </div>
            <v-btn
              class="mx-2"
              name="minus"
              fab
              small
              color="indigo"
              :disabled="anzahlFragen == 1 || veraenderbar"
              @click="frageLoeschen"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="veraenderbar"
              class="mx-2"
              name="plus"
              fab
              small
              color="indigo"
              @click="frageHinzufuegen"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="isSaving"
            text
            color="primary"
            @click="weiter"
          >
            <v-icon large>
              mdi-arrow-left
            </v-icon>
            Zurück
          </v-btn>
          <div class="flex-grow-1" />
          <v-btn
            :disabled="submitDisabled || veraenderbar"
            :loading="isSaving"
            name="submit"
            text
            color="primary"
            @click="submit"
          >
            Erstellen
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text>
          <div class="text-center progress-circular-container">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue";
export default {
  components: {
    FehlerMeldung,
    WarnungMeldung
  },
  props: {
    currentDate: {
      type: String,
      required: true,
      default: String
    }
  },
  data() {
    return {
      checkbox: false,
      isSaving: false,
      titel: "",
      gewichtung: 1,
      fragen: ["", "", ""],
      error: null,
      erstellt: false,
      isaktiverZeitraum: null,
      ready: false,
      themen: [],
      warnungtext:
        "Achtung! Themen können nur erstellt werden, wenn es gerade keinen aktiven Zeitraum gibt.",
      warnungvisible: false
    };
  },
  computed: {
    submitDisabled(){
      if (this.isSaving || this.checkTitel || this.checkFragen || this.checkGewichtung) {
        return true;
      }
      if (this.titel == "") return true;
      return false;
    },
    checkTitel() {
      return this.themen.includes(this.titel);
    },
    checkGewichtung(){
      return this.gewichtung <= 0;
    },
    checkFragen() {
      let ret = false;
      if (this.fragen) {
        this.fragen.forEach(frage => {
          if (frage == "") {
            ret = true;
          }
        });
      }
      return ret;
    },
    anzahlFragen() {
      if (this.fragen) {
        return this.fragen.length;
      }
      return 0;
    },
    veraenderbar() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.warnungvisible = this.isaktiverZeitraum;
      return this.isaktiverZeitraum;
    }
  },
  mounted(){
    this.$store.commit("title", "Thema hinzufügen");
    //Themen
    api
      .get(`/api/themen/`)
      .then(response => {
        const themen_titel = [];
        response.themen.forEach(thema => {
          themen_titel.push(thema.thema);
        });
        this.themen = themen_titel;
        //Zeitraum
        api
          .get(`/api/info/zeitraum`)
          .then(response => {
            if (
              (response && response.punktevergabe) ||
              (response && response.themenvergabe)
            ) {
              this.isaktiverZeitraum = true;
            } else this.isaktiverZeitraum = false;
            this.ready = true;
          })
          //Zeitraum
          .catch(error => {
            this.ready = true;
            this.error = error;
          });
      })
      //Themen
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  },
  methods: {
    async submit() {
      this.isSaving = true;
      this.error = null;
      const Fragen = [];
      this.fragen.forEach((fragen, index) => {
        const Frage = {
          id: index + 1,
          frage: fragen
        };
        Fragen.push(Frage);
      });
      const Thema = {
        thema: this.titel,
        fragen: Fragen,
        freigegeben: this.checkbox,
        gewichtung: this.gewichtung
      };

      const url = `/api/thema`;
      const postData = {
        thema: Thema
      };
      api
        .post(url, postData)
        .then(() => {
          this.erstellt = true;
          this.isSaving = false;
        })
        .catch(error => {
          this.isSaving = false;
          this.error = error;
        });
    },
    frageHinzufuegen() {
      this.fragen.push("");
    },
    frageLoeschen() {
      if (this.anzahlFragen != 1) this.fragen.pop();
    },
    weiter(){
      this.$router.push({ path: "/themenbearbeiten"})
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
  .p-rot-text{
    color:red
  }
</style>
