var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FehlerMeldung',{attrs:{"error":_vm.error}}),(!_vm.ready)?_c('div',{staticClass:"text-center progress-circular-container"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):(_vm.error && _vm.themen.length == 0)?_c('div',[_c('v-card',{staticClass:"mx-auto my-12",attrs:{"max-width":"800"}},[_c('v-card-title',[_vm._v(" Bewertung ")]),_c('v-card-text',[_vm._v(" Konnte nicht zum Bewerten wechseln! ")]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({ path: "/themen" })}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")]),_vm._v(" Zurück ")],1),_c('v-spacer')],1)],1)],1):_vm._e(),(!_vm.isLoading)?_c('v-stepper',{staticClass:"align-md-center",attrs:{"light":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_vm._l((_vm.fragen.length),function(n){return [_c('v-stepper-step',{key:(n + "-step"),attrs:{"editable":_vm.e1 >= n,"complete":_vm.e1 > n,"step":n}})]})],2),_c('v-stepper-items',_vm._l((_vm.fragen.length),function(n){return _c('v-stepper-content',{key:(n + "-content"),attrs:{"step":n}},[_c('v-card',{staticClass:"mx-auto my-12",attrs:{"max-width":"640"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.themen[0].thema)+" ")]),_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(_vm.getFrage(n))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-autocomplete',{ref:"person1",refInFor:true,attrs:{"items":_vm.getUnselectedItems(_vm.person1),"autocomplete":"off","autofocus":"","auto-select-first":"","hide-selected":"","color":"blue-grey lighten-2","label":_vm.getlabel3,"item-text":"name","item-value":"id","append-outer-icon":"mdi-playlist-plus"},on:{"click:append-outer":function($event){_vm.showOverview({
                      items: _vm.getUnselectedItems(_vm.person1),
                      person: 'person1',
                    })}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.person1 = null}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[(data.item.bild)?_c('v-img',{attrs:{"src":data.item.bild},on:{"error":function($event){return _vm.defImg(data.item)}}}):_c('v-icon',{attrs:{"size":30}},[_vm._v(" mdi-account-circle ")])],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [((typeof data.item) !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[(data.item.bild)?_c('img',{attrs:{"src":data.item.bild},on:{"error":function($event){return _vm.defImg(data.item)}}}):_c('v-icon',{attrs:{"size":40}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.name)}})],1)]]}}],null,true),model:{value:(_vm.person1),callback:function ($$v) {_vm.person1=$$v},expression:"person1"}})],1),_c('v-row',[_c('v-autocomplete',{ref:"person2",refInFor:true,attrs:{"items":_vm.getUnselectedItems(_vm.person2),"autocomplete":"off","auto-select-first":"","hide-selected":"","color":"blue-grey lighten-2","label":_vm.getlabel2,"item-text":"name","item-value":"id","append-outer-icon":"mdi-playlist-plus"},on:{"click:append-outer":function($event){_vm.showOverview({
                      items: _vm.getUnselectedItems(_vm.person2),
                      person: 'person2',
                    })}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.person2 = null}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[(data.item.bild)?_c('v-img',{attrs:{"src":data.item.bild},on:{"error":function($event){return _vm.defImg(data.item)}}}):_c('v-icon',{attrs:{"size":30}},[_vm._v(" mdi-account-circle ")])],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [((typeof data.item) !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[(data.item.bild)?_c('img',{attrs:{"src":data.item.bild},on:{"error":function($event){return _vm.defImg(data.item)}}}):_c('v-icon',{attrs:{"size":40}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.name)}})],1)]]}}],null,true),model:{value:(_vm.person2),callback:function ($$v) {_vm.person2=$$v},expression:"person2"}})],1),_c('v-row',[_c('v-autocomplete',{ref:"person3",refInFor:true,attrs:{"items":_vm.getUnselectedItems(_vm.person3),"autocomplete":"off","auto-select-first":"","hide-selected":"","color":"blue-grey lighten-2","label":_vm.getlabel1,"item-text":"name","item-value":"id","append-outer-icon":"mdi-playlist-plus"},on:{"click:append-outer":function($event){_vm.showOverview({
                      items: _vm.getUnselectedItems(_vm.person3),
                      person: 'person3',
                    })}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.person3 = null}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[(data.item.bild)?_c('v-img',{attrs:{"src":data.item.bild},on:{"error":function($event){return _vm.defImg(data.item)}}}):_c('v-icon',{attrs:{"size":30}},[_vm._v(" mdi-account-circle ")])],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [((typeof data.item) !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[(data.item.bild)?_c('img',{attrs:{"src":data.item.bild},on:{"error":function($event){return _vm.defImg(data.item)}}}):_c('v-icon',{attrs:{"size":40}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.name)}})],1)]]}}],null,true),model:{value:(_vm.person3),callback:function ($$v) {_vm.person3=$$v},expression:"person3"}})],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(n < _vm.fragen.length)?_c('v-btn',{attrs:{"disabled":_vm.submitDisabled,"text":"","color":"primary"},on:{"click":function($event){return _vm.nextStep(n)}}},[_vm._v(" Weiter "),_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1):_c('v-btn',{attrs:{"disabled":_vm.submitDisabled,"loading":_vm.buttonLoading,"text":"","color":"primary"},on:{"click":function($event){return _vm.nextStep(n)}}},[_vm._v(" Abschliessen "),_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-send ")])],1)],1)],1)],1)}),1),_c('DialogMitarbeiter',{attrs:{"visible":_vm.dialogVisible,"items":_vm.dialogItems},on:{"selected":_vm.onClickMitarbeiter,"close":function($event){_vm.dialogVisible = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }