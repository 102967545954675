<template>
  <v-card
    class="mx-auto my-12"
    max-width="800"
  >
    <v-card-title>
      Anzahl Bewertungen pro Thema
    </v-card-title>
    <v-card-text v-if="ready && diagrammready">
      <div v-if="keinedaten">
        Keine Daten gefunden
      </div>
      <div v-else>
        <DiagrammPie
          v-if="chartdata && options"
          :chartdata="chartdata"
          :options="options"
        />
      </div>
    </v-card-text>
    <v-card-text v-else>
      <div class="text-center progress-circular-container">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import DiagrammPie from "@/components/Diagramme/DiagrammPie.vue";
export default {
  components: {
    DiagrammPie
  },
  props: {
    bewertungen: {
      type: Array,
      required: true
    },
    ready: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    //bewertungen:[],
    keinedaten: false,
    diagrammready: true,
    options: {
      legend: {
        position: 'bottom'
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      }
    },
    chartdata: null,
    themenColors: {
      1: '#385F73',
      2: '#1F7087',
      3: '#42A5F5',
      4: '#EF5350',
      5: '#952175',
      6: '#26A69A',
      7: '#AED581',
      8: '#665191',
      9: '#a05195',
      10: '#d45087',
      11: '#f95d6a',
      12: '#ffa600'

    }
  }),
  computed: {},
  watch: {
    bewertungen: function() {
      const data = []
      const label = []
      const daten = []
      const ids = []
      data.push({
        label:"Pie",
        backgroundColor: [],
        data: []
      })
      if(this.bewertungen.length == 0){
        this.keinedaten = true
      }else{
        this.keinedaten = false
      }
      //Geht alle Bewertugnen durch
      this.bewertungen.forEach(bew=> {
        const found = ids.find(element => element == bew.thema_id);
        if(found == undefined){
          ids.push(bew.thema_id)
          const index = ids.indexOf(bew.thema_id);
          label[index] = (bew.thema.name)
          daten[index] = 1
        }else{
          const index = ids.indexOf(bew.thema_id);
          daten[index] = daten[index] + 1
        }
      })

      //Speichert Data
      const data2 = []
      daten.forEach(da => {
        if(da > 0){
          data2.push(da)
        }
      })
      data[0].data = data2

      //Speichert Labels
      const label2 = []
      label.forEach(lab => {
        label2.push(lab)
      })

      //Speichert BackgroudColor
      ids.forEach((id,index) => {
        data[0].backgroundColor.push(this.themenColors[(index % 12) + 1])
      })

      //ändert ChartData
      this.chartdata = {
        labels: label2,
        datasets: data

      }
      this.diagrammready = true

    }
  }
};
</script>
