<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <WarnungMeldung
      :message="warnungtext"
      :visible="warnungvisible"
    />
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        Blacklist bearbeiten
      </v-card-title>
      <div v-if="gespeichert">
        <v-card-text>
          Zur Blacklist hinzugefügt!
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="gespeichert = false"
          >
            Weiter
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text v-if="ready && blacklist">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
          <v-data-table
            :search="search"
            :headers="headers"
            :items="getData"
            :items-per-page="10"
            class="elevation-1"
            :loading="getloading"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                :disabled="Veraenderbar || error != null"
                icon
                @click="openDialog(item)"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
              <DialogBestaetigen
                :id="delid"
                :visible="dialogVisible"
                titel="Blacklist"
                :frage="delfrage"
                @bestaetigen="deleteEintrag"
                @close="dialogVisible = false"
              />
            </template>
          </v-data-table>
          <div v-if="!Veraenderbar && error == null">
            <v-container>
              <v-row>
                <AutocompleteMitarbeiter
                  :clear="clear"
                  label="Mitarbeiter"
                  :people="people"
                  :exclude-ids="blacklistids"
                  @select="selectMitarbeiter"
                />
                <div class="flex-grow-1" />

                <v-btn
                  :disabled="submitDisabled"
                  :loading="isSaving"
                  text
                  color="primary"
                  style="margin:auto"
                  @click="save(getSelected)"
                >
                  Hinzufügen
                  <v-icon large>
                    mdi-send
                  </v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <!--ladet bevor die Daten das erste mal geholt werden-->
        <v-card-text v-else>
          <div class="text-center progress-circular-container">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import AutocompleteMitarbeiter from "@/components/Autocomplete/AutocompleteAddMitarbeiter.vue";
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue";
import api from "@/utils/api";
export default {
  components: {
    AutocompleteMitarbeiter,
    DialogBestaetigen,
    FehlerMeldung,
    WarnungMeldung
  },
  props: {
    currentDate: {
      type: String,
      required: false,
      default: String
    }
  },
  data() {
    return {
      ready: false,
      search: "",
      gespeichert: null,
      isSaving: false,
      loading: true,
      error: null,
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Aktionen", value: "actions", sortable: false }
      ],
      delfrage: " ",
      delid: -1,
      dialogVisible: false,
      blacklist: null,
      people: [],
      selected: {},
      //zum leeren des Autocomplete
      clear: false,
      isaktiverZeitraum: null,
      warnungvisible: false,
      warnungtext:
        "Achtung! Blacklist kann nur bearbeitet werden, wenn es gerade keinen aktiven Zeitraum gibt."
    };
  },

  computed: {
    getData() {
      if (this.blacklist) return this.blacklist;
      else return [];
    },
    submitDisabled() {
      if (this.selected) return false;
      else return true;
    },
    getSelected() {
      return this.selected;
    },
    //gibt ein Array zurück mit den Ids aus der Blacklist
    blacklistids() {
      const ret = [];
      this.blacklist.forEach(eintrag => {
        ret.push(eintrag.id);
      });
      return ret;
    },
    getloading() {
      return this.loading;
    },

    Veraenderbar() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.warnungvisible = this.isaktiverZeitraum;
      return this.isaktiverZeitraum;
    }
  },
  mounted() {
    this.updateList();
    //bekommt den aktuelle Zeitraum
    api
      .get(`/api/info/zeitraum`)
      .then(response => {
        if (
          (response && response.punktevergabe) ||
          (response && response.themenvergabe)
        ) {
          this.isaktiverZeitraum = true;
        } else this.isaktiverZeitraum = false;
        this.ready = true;
        //bekommt die MitarbeiterListe ohne Admins
        api
          .get("/api/blacklist/mitarbeiter")
          .then(response => {
            this.people = response.mitarbeiter;
          })
          //Mitarbeiter
          .catch(error => {
            this.error = error;
            this.ready = true;
          });
      })
      //Zeitraum
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  },
  methods: {
    selectMitarbeiter({ value }) {
      this.selected = value;
    },
    //Fügt den Mitarbeiter mit der übergebenen Id zur Blacklist hinzu
    save(id) {
      if (!this.Veraenderbar) {
        const url = `/api/blacklist/`;
        const postData = {
          mitarbeiter: this.people.find(peo => peo.id == id)
        };
        api
          .post(url, postData)
          .then(() => {
            this.clear = !this.clear;
            this.gespeichert = true;
            this.updateList();
          })
          .catch(error => {
            this.error = error;
          });
      }
    },
    /*Öffnet den Dialog zum löschen eines Mitarbeiters aus der Blacklist
      setzt vorher noch die Frage bzw. die ID die der Dialog hernehmen soll */
    openDialog(item) {
      this.delfrage = "Mitarbeiter " + item.name + " von Blacklist entfernen?";
      this.delid = item.id;
      this.dialogVisible = true;
    },
    //Löscht den Benutzer aus der Blacklist, dessen Id übergeben wurde
    deleteEintrag(id) {
      this.dialogVisible = false;
      this.error = null;
      if (id) {
        const url = `/api/blacklist/${id}`;
        api
          .del(url)
          .then(() => {
            this.updateList();
          })
          .catch(error => {
            this.error = error;
          });
      }
    },
    //aktualisiert die BlackList
    updateList() {
      const url2 = `/api/blacklist/`;
      this.loading = true;
      api
        .get(url2)
        .then(response => {
          this.blacklist = response.blacklist;
          this.loading = false;
        })
        .catch(error => {
          //Damit es aufhört zu laden, das erste mal
          if (this.blacklist == null) {
            this.blacklist = [];
          }
          this.loading = false;
          this.error = error;
        });
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
