<template>
  <v-dialog
    :value="visible"
    width="500"
    :retain-focus="false"
    persistent
  >
    <v-card>
      <v-toolbar
        dark
        dense
        class="primary"
      >
        <v-toolbar-title>
          {{ titel }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        <span style="white-space: pre-line">
          {{ frage }}
        </span>
      </v-card-title>
      <v-divider />
      <v-card-actions fixed>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="$emit('close')"
        >
          Abbrechen
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="onbestaetigt"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: Number
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    frage: {
      type: String,
      required: true,
      default: String
    },
    titel: {
      type: String,
      required: true,
      default: String
    }
  },

  data() {
    return {};
  },
  methods: {
    //Beim bestätigen wird die Id übergeben, falls sie vorhanden ist
    onbestaetigt() {
      if (this.id || this.id == 0) this.$emit("bestaetigen", this.id);
      else this.$emit("bestaetigen");
    }
  }
};
</script>
