<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <WarnungMeldung
      :message="warnung.text"
      :visible="warnung.visible"
    />
    <v-card
      max-width="800"
      class="mx-auto my-12"
    >
      <v-card-title>
        Zeitraum bearbeiten
      </v-card-title>
      <div v-if="gespeichert">
        <v-card-text>
          Der Zeitraum wurde gespeichert!
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="gespeichert = false"
          >
            Weiter
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text v-if="zeitraumready">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <!--Input Date beginn-->
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="beginn"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on: menu, attrs }">
                    <v-text-field
                      v-model="beginn"
                      :disabled="error != null || isSaving || warnung.visible"
                      label="Beginn:"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="menu"
                    />
                  </template>
                  <v-date-picker
                    v-model="beginn"
                    no-title
                    scrollable
                    :min="currentDate"
                    :max="maxDateBeginn"
                    @input="$refs.menu.save(beginn)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Schließen
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <!--Input Date ende-->
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="ende"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on: menu2, attrs2 }">
                    <v-text-field
                      v-model="ende"
                      :disabled="error != null || isSaving || warnung.visible"
                      label="Ende:"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs2"
                      v-on="menu2"
                    />
                  </template>
                  <v-date-picker
                    v-model="ende"
                    no-title
                    scrollable
                    :min="minDateEnde"
                    @input="$refs.menu2.save(ende)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu2 = false"
                    >
                      Schließen
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
        <v-card-actions v-if="!warnung.visible && zeitraumready">
          <v-spacer />
          <v-btn
            :disabled="delDisabled || isSaving"
            text
            color="primary"
            @click="openDialog()"
          >
            Löschen
            <v-icon large>
              mdi-delete
            </v-icon>
          </v-btn>
          <DialogBestaetigen
            :visible="dialogVisible"
            titel="Jahreabschluss Zeitraum"
            :frage="delfrage"
            @bestaetigen="del"
            @close="dialogVisible = false"
          />
          <v-btn
            :disabled="submitDisabled"
            :loading="isSaving"
            text
            color="primary"
            @click="save"
          >
            Speichern
            <v-icon large>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="zeitraumready">
          <v-spacer />
          <v-btn
            text
            color="primary"
            :to="{ name: 'abschlussbewertungauswertung' }"
          >
            Zur Auswertung
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>
<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue"
export default {
  components: {
    FehlerMeldung,
    DialogBestaetigen,
    WarnungMeldung

  },
  props: {
    currentDate: {
      type: String,
      required: false,
      default: String
    }
  },
  data() {
    return {
      isSaving: false,
      error: null,
      beginn: null,
      ende: null,
      menu: false,
      menu2: false,
      zeitraumready: false,
      zeitraum: null,
      gespeichert: false,
      dialogVisible: false,
      delfrage: "Jahreabschluss Zeitraum wirklich löschen?",
      warnung: {
        visible: false,
        text: "Zeitraum kann nur gelöscht werden, wenn es keine Jahresabschlussbwertungen mehr gibt!"
      },
      abstimmung: ""
    };
  },
  computed: {
    minDateEnde() {
      if (this.beginn) return this.beginn;
      else return this.currentDate;
    },
    maxDateBeginn() {
      if (this.ende) return this.ende;
      else return null;
    },
    submitDisabled(){
      if (!this.ende || !this.beginn) return true;
      if (
        this.zeitraum &&
        this.zeitraum.beginn == this.beginn &&
        this.zeitraum.ende == this.ende
      )
        return true;
      if (!this.zeitraum && this.beginn && this.ende) return false;
      return false
    },
    delDisabled() {
      if (this.zeitraum == null) return true;
      return false
    }
  },
  mounted() {
    //holt sich den Zeitraum von der DB
    const url = `/api/zeitraum`;
    api
      .get(url)
      .then(response => {
        if (
          response.zeitraum &&
          response.zeitraum[0] &&
          response.zeitraum[0].beginn &&
          response.zeitraum[0].ende
        ) {
          this.beginn = response.zeitraum[0].beginn;
          this.ende = response.zeitraum[0].ende;
          this.zeitraum = response.zeitraum[0];
        }
        const statusUrl = `/api/abschluss/habenabgestimmt`;
        //haben abgestimmt
        this.warnung.visible = false
        api
          .get(statusUrl)
          .then(response => {
            this.abstimmung = response.habenabgestimmt;
            if(this.abstimmung.charAt(0)  != "0" && this.zeitraum != null){
              this.warnung.visible = true
            }else {
              this.warnung.visible = false
            }
            this.zeitraumready = true;
          }).catch(error => {
            this.zeitraumready = true;
            this.error = error
          })
      })
      .catch(error => {
        this.zeitraumready = true;
        this.error = error;
      });
  },
  methods: {
    save() {
      if (this.ende && this.beginn) {
        const zeitraum = {
          beginn: this.beginn,
          ende: this.ende
        };

        this.isSaving = true;
        this.error = null;
        const url = `/api/zeitraum`;
        const postData = {
          zeitraum: zeitraum
        };
        api
          .post(url, postData)
          .then(() => {
            this.isSaving = false;
            this.zeitraum = zeitraum;
            if (
              zeitraum.beginn <= this.currentDate &&
              zeitraum.ende >= this.currentDate
            ) {
              this.$store.commit("ispunktevergabe", true);
            } else {
              this.$store.commit("ispunktevergabe", false);
            }
            if (zeitraum.ende <= this.currentDate) {
              this.$store.commit("ispunktevergabevorbei", true);
            } else {
              this.$store.commit("ispunktevergabevorbei", false);
            }
            this.gespeichert = true;
          })
          .catch(error => {
            this.isSaving = false;
            this.error = error;
          });
      }
    },
    //Der Zeitraum wird in der DB gelöscht
    del() {
      this.isSaving = true;
      const url = `/api/zeitraum`;
      this.dialogVisible = false;
      api
        .del(url)
        .then(() => {
          this.isSaving = false;
          this.zeitraum = null;
          this.beginn = null;
          this.ende = null;
          this.$store.commit("ispunktevergabe", false);
          this.$store.commit("ispunktevergabevorbei", false);
        })
        .catch(error => {
          this.isSaving = false;
          this.error = error;
        });
    },
    openDialog(){
      this.dialogVisible = true;
    }
  }
};
</script>
