<template>
  <div>
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <WarnungMeldung
      :message="warnungtext"
      :visible="warnungvisible"
    />
    <v-card
      class="mx-auto my-12"
      max-width="574"
    >
      <v-card-title>
        Jahresabschluss {{ getYear }}
      </v-card-title>
      <span v-if="!ready">
        <div class="text-center progress-circular-container">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </span>
      <span v-else-if="bereitsAbgestimmt">
        <v-card-text>
          Deine Stimme wurde gezählt. Vielen Dank!
        </v-card-text>
      </span>
      <span v-else>
        <v-card-text>
          <v-col cols="12">
            <v-row
              v-for="m in [0,1,2,3,4,5,6]"
              :key="m"
            >
              <AutocompleteMitarbeiter
                :id="m"
                :disabled="!isaktiverZeitraum"
                :people="people"
                :exclude-ids="mitarbeiter"
                :initial="initialMitarbeiter[m]"
                @select="selectMitarbeiter"
              />
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            :disabled="submitDisabled"
            :loading="isSaving"
            text
            color="primary"
            @click="submit"
          >
            Abschliessen
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </span>
    </v-card>
  </div>
</template>

<script>
import api from '@/utils/api'
import Vue from 'vue'
import AutocompleteMitarbeiter from '@/components/Autocomplete/AutocompleteMitarbeiter.vue'
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue";


export default {
  components: {
    AutocompleteMitarbeiter,
    FehlerMeldung,
    WarnungMeldung
  },

  data () {
    return {
      mitarbeiter: [],
      initialMitarbeiter: [],
      people: [],
      isLoading: true,
      bereitsAbgestimmt: false,
      isSaving: false,
      warnungvisible: false,
      warnungtext:
        "Achtung! Es kann nur abgestimmt werden, wenn es gerade einen aktiven Zeitraum für die Punktevergabe gibt.",
      error: null,
      isaktiverZeitraum: false,
      ready: false
    }
  },

  computed: {
    submitDisabled () {
      if (this.bereitsAbgestimmt || this.isSaving || !this.isaktiverZeitraum) {
        return true
      }
      if (this.mitarbeiter.length === 7) {
        let selectedMitarbeiter = 0
        this.mitarbeiter.forEach(i => {
          if (i) {
            selectedMitarbeiter++
          }
        })
        if (selectedMitarbeiter === 7) {
          return false
        } else {
          return true
        }
      }
      return true
    },
    getYear(){
      return new Date().getFullYear()
    }
  },

  watch: {
    e1 (val) {
      this.restoreAntworten(val)
    }
  },

  mounted () {
    this.$store.commit('title', "Jahresabschlussbewertung")
    api.get('/api/mitarbeiter').then(response => {
      this.people = response.mitarbeiter
      api.get('/api/abschluss/vorauswahl').then(response => {
        let counter = 0
        response.mitarbeiter.forEach(m => {
          if (counter <= 6) {
            Vue.set(this.initialMitarbeiter, counter, m[0].id)
            counter++
          }
        })
        api
          .get(`/api/info/zeitraum`)
          .then(response => {
            if (response && response.punktevergabe) {
              this.isaktiverZeitraum = true;
              this.warnungvisible = false
            } else {
              this.isaktiverZeitraum = false;
              this.warnungvisible = true;
            }
            this.ready = true;
          })
          //Zeitraum
          .catch(error => {
            this.ready = true;
            this.error = error;
          });
      }).catch(error=>{
        this.error = error
        this.ready = true
      })
    }).catch(error=> {
      this.error = error
      this.ready = true
    })
    this.checkBereitsAbgestimmt()
  },

  methods: {
    selectMitarbeiter ({ id, value }) {
      Vue.set(this.mitarbeiter, id, value)
    },
    async submit () {
      this.isSaving = true
      this.error = null
      const items = []
      this.mitarbeiter.forEach(id => {
        const idName = {
          id: id,
          name: this.people.filter(item => item.id === id)[0].name
        }
        items.push(idName)
      })
      const url = `/api/abschluss`
      const postData = {
        bewertung: items
      }
      await this.checkBereitsAbgestimmt()
      if (!this.bereitsAbgestimmt) {
        try{
          await api.post(url, postData)
          //await new Promise(resolve => setTimeout(resolve, 2000))
          await this.checkBereitsAbgestimmt()
        }catch(error){
          this.error = error
        }
      }
      this.isSaving = false
    },
    async checkBereitsAbgestimmt () {
      try{
        const response = await api.get('/api/abschluss/bereitsabgestimmt')
        if (response && response.bereitsabgestimmt >= 1) {
          this.bereitsAbgestimmt = true
        } else {
          this.bereitsAbgestimmt = false
        }
      }catch(error){
        this.error = error
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
