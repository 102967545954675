<template>
  <v-alert
    v-if="getVisible"
    max-width="800"
    class="mx-auto"
    :value="getMessage !== null"
    type="warning"
    outlined
    prominent
  >
    {{ getMessage }}
  </v-alert>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: String
    },
    visible:{
      type:Boolean,
      required: false,
      default: Boolean
    }
  },
  computed: {
    getMessage() {
      if (this.message) return this.message;
      else return "Keine Warnung gefunden!";
    },
    getVisible(){
      return this.visible
    }
  }
};
</script>
