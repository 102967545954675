






import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  data: () => ({
    //
  }),

  created () {
    const d = new Date()
    localStorage.setItem('programDate', `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`)
  }

})
