<template>
  <div>
    <FehlerMeldung
      v-if="error"
    />
    <div v-if="ready">
      <v-card
        v-if="themen.length == 0"
        ref="message"
        class="mx-auto my-12"
        max-width="800"
      >
        <v-card-title>
          Keine Themen gefunden
        </v-card-title>
      </v-card>
      <div v-else>
        <!--Die Tabelle-->
        <v-data-table
          ref="datatable"
          :headers="headers"
          :items="themen"
          item-key="id"   
          :expanded.sync="expanded"
          show-expand
          @click:row="clicked"
        >
          <!--Dropdown -->
          <template #expanded-item="{ item }">
            <td :colspan="2">
              <v-checkbox
                v-if="showFreigegeben"
                v-model="item.freigegeben"
                label="Freigeben"
                required
                readonly
              />
              <v-text-field
                v-model="item.gewichtung"
                readonly
                label="Gewichtung"
                type="number"
              />
              <v-list>
                <!-- Fragen -->
                <v-list-item
                  v-for="(frage, i) in item.fragen"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-circle-medium</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div>
                      {{ frage.frage }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div
      v-else
      ref="loading"
      class="text-center progress-circular-container"
    >
      <v-progress-circular
        name="v-progress-circular"
        :size="50"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
export default {
  components: {
    FehlerMeldung
  },
  props: {
    themen: {
      type: Array,
      required: true
    },
    showFreigegeben:{
      type: Boolean,
      required: true,
      default: false
    },
    ready: {
      type: Boolean,
      required: true
    },
    error:{
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    headers: [
      { text: "Thema", value: "thema" },
      { text: "", value: "data-table-expand" }
    ],
    expanded: []
  }),
  methods: {
    clicked(value) {
      if (this.expanded.includes(value)){
        this.expanded = this.expanded.filter(item  => item != value);
      }
      else {
        this.expanded.push(value)
      }
    }
  }
};
</script>
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
<style scoped>
.progress-circular-container{
  padding-top: 50px;
  padding-bottom: 50px
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
 