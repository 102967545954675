<template>
  <v-dialog
    :value="visible"
    width="800"
    :retain-focus="false"
    persistent
  >
    <v-card>
      <v-toolbar
        dark
        dense
        class="primary"
      >
        <v-toolbar-title>
          Themenzeitraum
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        <span v-if="item">
          Zeitraum bearbeiten
        </span>
        <span v-else>
          Zeitraum erstellen
        </span>
      </v-card-title>
      <div>
        <v-card-text>
          <v-expand-transition>
            <div v-show="show">
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <!--Input Date beginn-->
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="beginn"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on: menu, attrs }">
                        <v-text-field
                          v-model="beginn"
                          prepend-inner-icon="mdi-calendar"
                          label="Beginn:"
                          readonly
                          v-bind="attrs"
                          v-on="menu"
                        />
                      </template>
                      <v-date-picker
                        v-model="beginn"
                        no-title
                        scrollable
                        :min="minDateBeginn"
                        :max="maxDateBeginn"
                        :allowed-dates="allowedDates"
                        @input="$refs.menu.save(beginn)"
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="closemenu()"
                        >
                          Schließen
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <!--Input Date ende-->
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="ende"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on: menu2, attrs2 }">
                        <v-text-field
                          v-model="ende"
                          label="Ende:"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs2"
                          v-on="menu2"
                        />
                      </template>
                      <v-date-picker
                        v-model="ende"
                        no-title
                        scrollable
                        :min="minDateEnde"
                        :max="maxDateEnde"
                        :allowed-dates="allowedDates"
                        @input="$refs.menu2.save(ende)"
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="closemenu2()"
                        >
                          Schließen
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-show="!show">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
              <v-data-table
                v-model="selected"
                show-select
                :search="search"
                :headers="headers"
                :items="getData"
                :items-per-page="10"
                :loading="getloading"
              />
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            @click="close()"
          >
            <v-icon large>
              mdi-window-close
            </v-icon>
            Schließen
          </v-btn>

          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="show = !show"
          >
            <div v-if="show">
              Weiter
              <v-icon large>
                mdi-arrow-right
              </v-icon>
            </div>
            <div v-if="!show">
              <v-icon large>
                mdi-arrow-left
              </v-icon>
              Zurück
            </div>
          </v-btn>

          <v-btn
            v-if="item"
            text
            color="primary"
            @click="deleteItem"
          >
            Löschen
            <v-icon large>
              mdi-delete
            </v-icon>
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="submitDisabled"
            @click="save"
          >
            <span v-if="item">
              Speichern
            </span>
            <span v-else>
              Hinzufügen
            </span>
            <v-icon large>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      require: false,
      default: Object
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    list: {
      type: Array,
      required: true,
      default: Array
    },
    themen: {
      type: Array,
      require: true,
      default: Array
    }
  },

  data() {
    return {
      beginn: null,
      ende: null,
      menu: false,
      menu2: false,
      maxDateEnde: null,
      minDateBeginn: null,
      show: true,
      loading: false,
      search: "",
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Thema", value: "thema", sortable: false },
        { text: "Anzahl Fragen", value: "anzahlfragen", sortable: false },
      ],
      selected: []
    };
  },
  computed: {
    currentDate() {
      const current = new Date();
      let date = `${current.getFullYear()}`;
      if (current.getMonth() + 1 < 10)
        date = date + `-0${current.getMonth() + 1}`;
      else date = date + `-${current.getMonth() + 1}`;
      if (current.getDate() < 10) date = date + `-0${current.getDate()}`;
      else date = date + `-${current.getDate()}`;
      return date;
    },
    minDateEnde() {
      if (this.beginn) return this.beginn;
      else return this.currentDate;
    },
    maxDateBeginn() {
      if (this.ende) return this.ende;
      else return null;
    },
    submitDisabled() {
      if (!this.ende || !this.beginn || this.selected.length == 0) return true;

      return false
    },
    delDisabled() {
      if (this.item == null) return true;
      return false
    },
    getData() {
      return this.themen;
    },
    getloading() {
      return this.loading;
    }
  },
  watch: {
    visible() {
      if (this.item && this.item.beginn && this.item.ende) {
        this.setBeginn(this.item.beginn);
        this.setEnde(this.item.ende);
        if(this.item.themen){
          this.selected = [];
          const themenIds = [];
          this.item.themen.forEach(eintrag =>{
            themenIds.push(eintrag.id);
          });
          this.themen.forEach(thema => {
            if(this.item.themen.includes(thema.id)){
              this.selected.push(thema);
            }
          });
        }
      } else {
        this.selected = [];
        this.setBeginn(null);
        this.setEnde(null);
      }
    },
    beginn() {
      this.setmaxDateEnde();
    },
    ende() {
      this.setminDateBeginn();
    }
 
  },
  mounted() {
    this.setmaxDateEnde();
    this.setminDateBeginn();
  },
  methods: {
    resetDialog(){
      this.show = true;
      this.selected = [];
    },
    close(){
      this.$emit('close');
      this.resetDialog();
    },
    closemenu(){
      this.menu2 = false;
      this.resetDialog();
    },
    closemenu2(){
      this.menu2 = false;
      this.resetDialog();
    },
    save() {
      const themenIds = [];
      this.selected.forEach(thema => {
        const id = thema.id;
        themenIds.push(id);
      })
      if (this.item) {
        this.$emit("update", this.item.id, this.beginn, this.ende, this.item.mitarbeiter_anzahl, themenIds);
      } else {
        this.$emit("new", this.beginn, this.ende, themenIds);
      }
      this.resetDialog();
    },
    deleteItem() {
      if (this.item) {
        this.$emit("del", this.item.id);
        this.$emit("close");
        this.resetDialog();
      }
    },
    //gibt ein Array zurück, dass alle nicht erlaubten Datume beinhalten
    getdisabledDates() {
      let dates = [];
      this.list.forEach(zeitraum => {
        if(!this.item ||this.item && this.item.id != zeitraum.id)
          dates = dates.concat(this.getDaysArray(zeitraum.beginn, zeitraum.ende));
      });
      return dates;
    },
    //gibt ein Array zurück das alle Datume zwischen den 2 übergebenen Datume beinhaltet
    getDaysArray(start, end) {
      const arr = [];
      const end1 = new Date(end);
      for (let dt = new Date(start); dt <= end1; dt.setDate(dt.getDate() + 1)) {
        arr.push(dt.toJSON().slice(0, 10));
      }
      return arr;
    },
    //Kontrolliert ob das übergebene Datum erlaubt ist oder nicht, wird vom DatePicker verwendet
    allowedDates(val) {
      return !(
        (
          this.getdisabledDates().find(item => {
            return item == val;
          }) || []
        ).length > 0
      );
    },
    //Gibt das maximale Datum zurück das das Ende haben kann, sodass kein ZR den anderen überschneidet (null wenn es keine Begrenzung gibt)
    getMin() {
      let min = null;
      this.list.forEach(zr => {
        if((this.ende && zr.ende >= this.ende || !this.ende && this.beginn && zr.ende >= this.beginn) &&
           (min == null || zr.beginn < min) &&
           (!this.item|| (this.item.id && this.item.id != zr.id)))
        {
          min = zr.beginn
        }
      })
      return min;
    },
    //Gibt das minimale Datum zurück das Der Beginn haben kann, sodass kein ZR den anderen überschneidet (heutige Datum wenn es keine Begrenzung gibt)
    getMax() {
      let max = null;
      this.list.forEach(zr => {
        if((this.beginn && zr.ende <= this.beginn || !this.beginn && this.ende && zr.ende <= this.ende) &&
           (max == null || zr.ende > max) &&
           (!this.item|| (this.item.id && this.item.id != zr.id)))
        {
          max = zr.ende
        }
      })
      if(max == null){
        max = this.currentDate
      }
      return max;
    },
    setmaxDateEnde() {
      const min = this.getMin();
      this.maxDateEnde = min;
    },
    setminDateBeginn() {
      const max = this.getMax();
      this.minDateBeginn = max;
    },
    setEnde(ende){
      this.ende = ende
    },
    setBeginn(beginn){
      this.beginn = beginn
    }
  }
};
</script>
